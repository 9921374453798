<template>
	<div class="introduction">
		<div
			v-for="(item,index) in editableAbout"
			:key="index"
			class="about">
			<textarea
				ref="editableLine"
				type="text"
				name="editableLine"
				id="editableLine"
				v-if="item.edit"
				v-model="item.line"
				@keyup.enter="item.edit = false"
				@blur="item.edit = false"/>
			<p
				v-else
				class="line"
				:class="{'line--other-color': index % 2 !== 0}"
				@click="updateEditStatus(item)">{{ item.line }}</p>
			<i
				v-if="!item.edit"
				class="fas fa-trash-alt"
				@click="deleteLine(index)"></i>
		</div>
		<div class="add-new-line">
			<input
				type="text"
				name="addLine"
				id="addLine"
				placeholder="Add a new statement to the stack!"
				v-model="newLine"
				@keyup.enter="addNewLine(newLine)" />
			<button @click="getNewAbout" class="btn btn-success save-about">Save instance</button>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'EditIntroduction',
	data() {
		return {
			editableAbout: [],
			newLine: '',
			about: []
		}
	},
	methods: {
		...mapActions({
			saveAbout: 'updateAbout'
		}),
		updateAbout() {
			this.saveAbout(this.about);
			this.$emit('notification', 'Changes saved locally!');
		},
		loadAndModifyData() {
			let about = this.$store.state.about;
			about.forEach(line => {
				this.addNewLine(line);
			});
		},
		updateEditStatus(item) {
			this.editableAbout.map((a) => {
				return a.edit = false;
			})
			item.edit = true;
			setTimeout(() => {
				this.$refs.editableLine.focus();
			},100)
		},
		addNewLine(line) {
			let temp = {
				line: line,
				edit: false
			};
			this.editableAbout.push(temp);
			this.newLine = '';
		},
		deleteLine(index) {
			this.editableAbout.splice(index, 1);
		},
		getNewAbout() {
			// setInterval(() => {
				this.about = [];
				this.editableAbout.map(item => {
					this.about.push(item.line);
				})
				this.updateAbout();
			// },10000);
		}
	},
	mounted() {
		this.loadAndModifyData()
	}
}
</script>

<style scoped>
.introduction {
	padding: 5rem 10vw;
	color: white;
	font-size: 1.2rem;
}

.about {
	display: flex;
	justify-content: center;
	align-self: center;
}

input[type=text], input[type=password], textarea {
	border-radius: 8px;
	padding: 8px;
	vertical-align: middle;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	text-align: center;
	width: 80%;
}
input[type=text]:focus, input[type=password]:focus, textarea:focus {
	background-color: #ddd;
	outline: none;
}

.line {
	padding: 0.75rem 1.2rem;
	margin-bottom: 0;
	cursor: pointer;
	margin: 0 1rem;
}
.line:hover {
	background-color: darkcyan;
	border-radius: 0.5rem;
	transition: all 100ms ease-in-out;
}
.line--other-color {
	color: #E5FF9E;
}
.fa-trash-alt {
	margin: auto 0;
	color: burlywood;
	background-color: #a90909;
	padding: 0.6rem;
	border-radius: 50%;
	filter: sepia(1);
	transition: all 200ms ease-in-out;
	cursor: pointer;
}
.fa-trash-alt:hover {
	filter: sepia(0)
}

.add-new-line {
	display: flex;
	margin: 0.75rem 0;
	grid-gap: 1rem;
	justify-content: center;
}

.save-about {
	width: 134px;
}
</style>